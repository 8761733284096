@tailwind base;
@tailwind components;
@import "react-big-calendar.css";

html,
body {
  height: 100%;
}

.rbc-timeslot-group {
  @apply border-gray-900;
}

.rbc-time-slot {
  border-color: black;
}
.rbc-timeslot-group:hover {
  @apply bg-white;
}

.rbc-day-slot .rbc-time-slot {
  border-color: #3b3b3b;
}

.rbc-toolbar button {
  color: #ffffff;
}

.rbc-slot-selection {
  @apply bg-gray-500;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  @apply bg-gray-800;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  @apply bg-black;
}
.StripeElement {
  border: 1px solid rgba(255, 255, 255, 0.23);
}
.StripeElement--empty {
  border: 1px solid rgba(255, 255, 255, 0.23);
}

@import "fontawesomestyles.css";

@tailwind utilities;
